import React from "react";
import PropTypes from 'prop-types';
import BootstrapTable from "fad-react-bootstrap-table-next";
import {Button} from "react-bootstrap-buttons";
import {ArrowClockwise, Check, Pencil, PlusSquare, Trash} from "react-bootstrap-icons";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class ReduxViewTableComponent extends React.Component {
    constructor(props) {
        super(props);
        //this.columns = []; //props.columns; // [{type: 'int/float/date/string', title: 'header', field: 'data field', total: true/false, sort: true/false, main: true/false}]
        this.onRowSelect = props.onRowSelect
        this.onDelete = props.onDelete
        this.onEdit = props.onEdit
        this.onAdd = props.onAdd
        this.onReloadNeeded = props.onReloadNeeded
        this.onApply = props.onApply
        this.isRowApplyable = props.isRowApplyable
        this.onRefresh = props.onRefresh

        this.addable = (props.onAdd !== undefined && props.onAdd !== null)
        this.editable = (props.onEdit !== undefined && props.onEdit !== null)
        this.deletable = (props.onDelete !== undefined && props.onDelete !== null)
        this.selectable = (props.onRowSelect !== undefined && props.onRowSelect !== null)
        this.applyable = (props.onApply !== undefined && props.onApply !== null)
        this.hasApplyCheck = (props.isRowApplyable !== undefined && props.isRowApplyable !== null)

        this.input_columns = props.columns;

        // Check and fix main field of columns
        let foundMain = false
        for (let col of props.columns) {
            if (col.main === true) {
                foundMain = true
                break
            }
        }
        if (foundMain === false) {
            props.columns[0].main = true
        }
        // Check and fix main field

        this.state = {
            columns: [],
            reloadNeeded: false,
            newRow: {},
            showAddDialog: false,
            showEditDialog: false,
            showApplyDialog: false,
            selectedRowIndex: null,
            isMobile: this.isMobile(window.innerWidth),
        }
        this.state.columns = this.fillColumns()
    }

    isMobile = (width) => {
        return width <= 768
    }

    fillColumns = (isMobile) => { // returns array of new columns
        console.log("fillColumns, state:", this.state)
        if (isMobile === undefined) {
            isMobile = this.state.isMobile
        }
        console.log("fillColumns, isMobile:", isMobile)

        let newColumns = []

        for (const col of this.input_columns) {
            let newCol = {}
            // type
            if (col.type === "int") {
                newCol.type = "number"
            } else if (col.type === "float") {
                newCol.type = "number"
            } else if (col.type === "date") {
                newCol.type = "date"
                newCol.formatter = (cell) => {
                    let dateObj = cell;
                    if (typeof cell !== 'object') {
                        dateObj = new Date(cell);
                    } return `${('0' + dateObj.getDate()).slice(-2)}/${('0' + (dateObj.getMonth() + 1)).slice(-2)}/${dateObj.getFullYear()}`;
                }
            } else if (col.type === "string") {
                newCol.type = "string"
            }
            // title
            newCol.text = col.title
            // field
            newCol.dataField = col.field
            // total
            if (col.total === true && (col.type === "int" || col.type === "float")) {
                newCol.footer = columnData => {
                    const total = columnData.reduce((acc, item) => acc + item, 0);
                    return total.toFixed(2);
                }
            } else {
                if (newColumns.length === 0) {
                    newCol.footer = this.props.t('Total')
                } else {
                    newCol.footer = ''
                }
            }
            // sort
            newCol.sort = col.sort
            // style
            if (col.field === "EmergencyFund") {
                newCol.style = (cell, row, rowIndex, colIndex) => {
                    if ("EmergencyFundID" in row && row.EmergencyFundID == null) {
                        return {backgroundColor: '#ffcccc'}
                    }
                    return {}
                }
            }
            // readonly
            if (col.readonly === true) {
                newCol.readonly = true
                if (col.valueToAdd !== undefined) {
                    newCol.valueToAdd = col.valueToAdd
                }
            } else {
                newCol.readonly = false
            }
            if (isMobile === true && col.main !== true) {
                newCol.hidden = true
            }
            newColumns.push(newCol)
        }

        if (this.applyable === true) {
            newColumns.push({
                dataField: "databasePkey",
                text: "",
                type: "string",
                editable: false,
                headerStyle: {
                    width: "50px"
                },
                formatter: (cell, row) => {
                    if (row && (!this.hasApplyCheck || this.isRowApplyable(row) === true))
                        return (
                            <Button
                                size="sm"
                                variant="danger"
/*                                onClick={() => {
                                    console.log("Apply of row with ID " + row.ID)
                                    setTimeout(() => {
                                        if (window.confirm(this.props.t('Do you want to apply row?'))) {
                                            console.log("Apply!")
                                            this.onApply(row);//.then(() => {this.onReloadNeeded()}); // we need to call Reload after apply complete!
                                        }
                                    }, 0);
                                }}*/
                                onClick={() => {
                                    console.log("Apply of row with ID " + row.ID)
                                    setTimeout(() => {
                                        this.showApplyDialog(true, row)
                                    }, 0);
                                }}
                            >
                                <Check/>
                            </Button>
                        );
                    return null;
                },
                footer: ''
            })
        }
        if (this.editable === true) {
            newColumns.push({
                dataField: "databasePkey",
                text: "",
                type: "string",
                editable: false,
                headerStyle: {
                    width: "50px"
                },
                formatter: (cell, row) => {
                    if (row)
                        return (
                            <Button
                                size="sm"
                                variant="danger"
                                onClick={() => {
                                    console.log("Edit of row with ID " + row.ID)
                                    setTimeout(() => {
                                        this.showEditDialog(true, row)
                                    }, 0);
                                }}
                            >
                                <Pencil/>
                            </Button>
                        )
                }
            })
        }
        if (this.deletable === true) {
            newColumns.push({
                dataField: "databasePkey",
                text: "",
                type: "string",
                editable: false,
                headerStyle: {
                    width: "50px"
                },
                formatter: (cell, row) => {
                    if (row)
                        return (
                            <Button
                                size="sm"
                                variant="danger"
                                onClick={() => {
                                    console.log("Delete of row with ID " + row.ID)
                                    setTimeout(() => {
                                        if (window.confirm(this.props.t('Do you want to delete row?'))) {
                                            console.log("Delete!")
                                            this.onDelete(row);
                                            this.onReloadNeeded();
                                        }
                                    }, 0);
                                }}
                            >
                                <Trash/>
                            </Button>
                        );
                    return null;
                },
                footer: ''
            })
        }
        console.log("Columns:", newColumns)
        return newColumns
    }

    handleWindowSizeChange = () => {
        const width = window.innerWidth
        const isMobile = this.isMobile(width)
        console.log("handleWindowSizeChange: ", width)
        this.setState({...this.state, isMobile: isMobile})
        this.setState({...this.state, columns: this.fillColumns(isMobile)})
    }

    async componentDidMount() {
        this.onReloadNeeded();
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    onInput = (e) => {
        console.log("Value[" + e.target.name + "] = " + e.target.value)
        this.setState({ ...this.state, newRow: {...this.state.newRow, [e.target.name]: e.target.value }});
        console.log("New Row: ", this.state.newRow)
    }

    onDateInput = (e) => {
        console.log("Value[" + e.target.name + "] = " + e.target.value)
        this.setState({ ...this.state, newRow: {...this.state.newRow, [e.target.name]: new Date(e.target.value) }});
        console.log("New Row: ", this.state.newRow)
    }

    onIntInput = (e) => {
        console.log("Value[" + e.target.name + "] = " + parseInt(e.target.value))
        this.setState({ ...this.state, newRow: {...this.state.newRow, [e.target.name]: parseInt(e.target.value) }});
        console.log("New Row: ", this.state.newRow)
    }

    onFloatInput = (e) => {
        console.log("Value[" + e.target.name + "] = " + parseFloat(e.target.value))
        this.setState({ ...this.state, newRow: {...this.state.newRow, [e.target.name]: parseFloat(e.target.value) }});
        console.log("New Row: ", this.state.newRow)
    }

    onAddFormSubmit = e => {
        e.preventDefault();
        this.submitForm();
        this.showAddDialog(false)
    }

    onEditFormSubmit = e => {
        e.preventDefault();
        this.submitEditForm();
        this.showEditDialog(false, {})
    }

    onApplyFormSubmit = e => {
        e.preventDefault();
        this.submitApplyForm();
        this.showApplyDialog(false, {})
    }

    showAddDialog(state) {
        console.log("showAddDialog, state: ", state)
        let row = {}
        for (const col of this.input_columns) {
            if (col.showOnAdd === false) {
                continue
            }
            if (col.readonly === true) {
                row[col.field] = col.valueToAdd
            }
            // Add default values to new row
            if (col.field === "Income") {
                for (const income of this.props.incomes) {
                    row = {...row, IncomeID: income.ID}
                    break
                }
            } else if (col.field === "Currency") {
                for (const currency of this.props.currency) {
                    row = {...row, CurrencyID: currency.ID}
                    break
                }
            }
        }
        console.log("showAddDialog, row with prefilled fields: ", row)
        this.setState({...this.state, showAddDialog: state, newRow: row});
    }

    showEditDialog(state, row) {
        console.log("showEditDialog(", state, row, ")")
        this.setState({...this.state, showEditDialog: state, newRow: row});
    }

    showApplyDialog(state, row) {
        console.log("showApplyDialog(", state, row, ")")
        this.setState({...this.state, showApplyDialog: state, newRow: row});
    }

    submitAddForm() {
        console.log("Add form submit")
        console.log("New Row:", this.state.newRow)
        this.onAdd(this.state.newRow)
        this.onReloadNeeded()
    }

    submitEditForm() {
        console.log("Edit form submit")
        console.log("Edited Row:", this.state.newRow)
        this.onEdit(this.state.newRow)
        this.onReloadNeeded()
    }

    submitApplyForm() {
        console.log("Apply form submit")
        console.log("Applyed Row:", this.state.newRow)
        this.onApply(this.state.newRow)
        this.onReloadNeeded()
    }

    handleOnRowSelect = (row, isSelect, rowIndex) => {
        if (isSelect) {
            console.log("Selected row", row)
            if (this.selectable) {
                this.setState({...this.state, selectedRowIndex: row["ID"]}) // ID is a value of Table.keyField parameter
                this.onRowSelect(row)
            }
        }
    }

    handleOnExpandRow = (row, isExpand, rowIndex, e) => {
        if (isExpand) {
            console.log("Expanded row", row)
            if (!this.selectable) {
                this.setState({...this.state, selectedRowIndex: row["ID"]}) // ID is a value of Table.keyField parameter
            }
        }
    }

    getSelectedRow = () => {
        return this.state.selectedRowIndex
    }

    createEmergencyFundsSelect = (col) => {
        const selectedId = this.state.newRow ? this.state.newRow.EmergencyFundID : null
        let options = [<option value={null}>None</option>]
        for (const fund of this.props.emergency_funds) {
            options.push(<option value={fund.ID}>{fund.Title}</option>)
        }
        return (<Form.Select size="sm" onChange={this.onIntInput} name="EmergencyFundID" placeholder="EmergencyFundID" value={selectedId} readOnly={col.readonly}>{options}</Form.Select>)
    }

    createCurrencySelect = (col) => {
        const selectedId = this.state.newRow ? this.state.newRow.CurrencyID : null
        let options = []
        for (const currency of this.props.currency) {
            options.push(<option value={currency.ID}>{currency.Symbol}</option>)
        }
        return (<Form.Select size="sm" onChange={this.onIntInput} name="CurrencyID" placeholder="CurrencyID" value={selectedId} readOnly={col.readonly} disabled={col.readonly}>{options}</Form.Select>)
    }

    createIncomeSelect = (col) => {
        const selectedId = this.state.newRow ? this.state.newRow.IncomeID : null
        let options = []
        for (const income of this.props.incomes) {
            const date = (typeof income.Date === "string" ? new Date(income.Date) : income.Date).toISOString().substr(0, 10)
            const text = income.Amount + ' / ' + date + ' / ' + income.Comment
            options.push(<option value={income.ID}>{text}</option>)
        }
        return (<Form.Select size="sm" onChange={this.onIntInput} name="IncomeID" placeholder="IncomeID" value={selectedId} readOnly={col.readonly} disabled={col.readonly}>{options}</Form.Select>)
    }

    render() {

        let addButton = this.addable === true ? (<Button onClick={() => {this.showAddDialog(true)}}><PlusSquare/></Button>) : (<p/>);
        let refreshButton = this.onRefresh != null && this.onRefresh !== undefined ? (<Button onClick={() => {this.onRefresh()}}><ArrowClockwise/></Button>) : (<p/>);

        let addModalFields = []
        for (const col of this.input_columns) {
            if (col.showOnAdd === false) {
                continue
            }
            let control = <></>
            if (col.field === "EmergencyFund") {
                control = this.createEmergencyFundsSelect(col)
            } else if (col.field === "Income") {
                control = this.createIncomeSelect(col)
            } else if (col.field === "Currency") {
                control = this.createCurrencySelect(col)
            } else if (col.type === "int") {
                control = <Form.Control type="number" min={0} placeholder={col.field} name={col.field} onChange={this.onIntInput} readOnly={col.readonly} value={col.valueToAdd}/>
            } else if (col.type === "float") {
                control = <Form.Control size="sm" type="number" step={0.01} precision={2} placeholder={col.field} name={col.field} onChange={this.onFloatInput} readOnly={col.readonly} value={col.valueToAdd}/>
            } else if (col.type === "date") {
                control = <Form.Control size="sm" type="date" placeholder={col.field} name={col.field} onChange={this.onDateInput} readOnly={col.readonly} value={col.valueToAdd}/>
            } else if (col.type === "string") {
                control = <Form.Control size="sm" type="text" placeholder={col.field} name={col.field} onChange={this.onInput} readOnly={col.readonly} value={col.valueToAdd}/>
            }
            addModalFields.push(
                <Row>
                    <Form.Label column="sm" lg={2}>{col.title}</Form.Label>
                    <Col>
                        {control}
                    </Col>
                </Row>);
        }

        let addModal = this.addable === true ? (
            <Modal show={this.state.showAddDialog} onHide={() => {this.showAddDialog(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.t('Add')}</Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <Form onSubmit={this.onAddFormSubmit}>
                        {addModalFields}
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {this.showAddDialog(false)}}>{this.props.t('Close')}</Button>
                    <Button variant="primary" onClick={() => {this.submitAddForm(); this.showAddDialog(false)}}>{this.props.t('Add')}</Button>
                </Modal.Footer>
            </Modal>
        ) : (<p/>);

        let editModalFields = []
        for (const col of this.input_columns) {
            if (col.editable === false) {
                continue
            }
            let control = <></>
            if (col.field === "EmergencyFund") {
                control = this.createEmergencyFundsSelect(col)
            } else if (col.field === "Income") {
                control = this.createIncomeSelect(col)
            } else if (col.field === "Currency") {
                control = this.createCurrencySelect(col)
            } else if (col.type === "int") {
                control = <Form.Control type="number" min={0} placeholder={col.field} name={col.field} onChange={this.onIntInput} defaultValue={eval('this.state.newRow.' + col.field)} readOnly={col.readonly}/>
            } else if (col.type === "float") {
                control = <Form.Control size="sm" type="number" step={0.01} precision={2} placeholder={col.field} name={col.field} onChange={this.onFloatInput} defaultValue={eval('this.state.newRow.' + col.field)} readOnly={col.readonly}/>
            } else if (col.type === "date") {
                control = <Form.Control size="sm" type="date" placeholder={col.field} name={col.field} onChange={this.onDateInput} defaultValue={eval('this.state.newRow.' + col.field) === undefined ? '' : eval('this.state.newRow.' + col.field).toISOString().substr(0, 10)} readOnly={col.readonly}/>
            } else if (col.type === "string") {
                control = <Form.Control size="sm" type="text" placeholder={col.field} name={col.field} onChange={this.onInput} defaultValue={eval('this.state.newRow.' + col.field)} readOnly={col.readonly}/>
            }
            editModalFields.push(
                <Row>
                    <Form.Label column="sm" lg={2}>{col.title}</Form.Label>
                    <Col>
                        {control}
                    </Col>
                </Row>);
        }

        let editModal = this.editable === true ? (
            <Modal show={this.state.showEditDialog} onHide={() => {this.showEditDialog(false, {})}}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.t('Edit')}</Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <Form onSubmit={this.onEditFormSubmit}>
                        {editModalFields}
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {this.showEditDialog(false, {})}}>{this.props.t('Close')}</Button>
                    <Button variant="primary" onClick={() => {this.submitEditForm(); this.showEditDialog(false, {})}}>{this.props.t('Save')}</Button>
                </Modal.Footer>
            </Modal>
        ) : (<p/>);

        let applyModalFields = []
        for (const col of this.input_columns) {
            if (col.editable !== true) {
                continue
            }
            let control = <></>
            if (col.field === "EmergencyFund") {
                control = this.createEmergencyFundsSelect(col)
            } else if (col.field === "Income") {
                control = this.createIncomeSelect(col)
            } else if (col.field === "Currency") {
                control = this.createCurrencySelect(col)
            } else if (col.type === "int") {
                control = <Form.Control type="number" min={0} placeholder={col.field} name={col.field} onChange={this.onIntInput} defaultValue={eval('this.state.newRow.' + col.field)} readOnly={col.readonly}/>
            } else if (col.type === "float") {
                control = <Form.Control size="sm" type="number" step={0.01} precision={2} placeholder={col.field} name={col.field} onChange={this.onFloatInput} defaultValue={eval('this.state.newRow.' + col.field)} readOnly={col.readonly}/>
            } else if (col.type === "date") {
                control = <Form.Control size="sm" type="date" placeholder={col.field} name={col.field} onChange={this.onDateInput} defaultValue={eval('this.state.newRow.' + col.field) === undefined ? '' : eval('this.state.newRow.' + col.field).toISOString().substr(0, 10)} readOnly={col.readonly}/>
            } else if (col.type === "string") {
                control = <Form.Control size="sm" type="text" placeholder={col.field} name={col.field} onChange={this.onInput} defaultValue={eval('this.state.newRow.' + col.field)} readOnly={col.readonly}/>
            }
            applyModalFields.push(
                <Row>
                    <Form.Label column="sm" lg={2}>{col.title}</Form.Label>
                    <Col>
                        {control}
                    </Col>
                </Row>);
        }

        let applyModal = this.applyable === true ? (
            <Modal show={this.state.showApplyDialog} onHide={() => {this.showApplyDialog(false, {})}}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.t('Do you want to apply row?')}</Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <Form onSubmit={this.onApplyFormSubmit}>
                        {applyModalFields.length === 0 ? "" : applyModalFields}
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {this.showApplyDialog(false, {})}}>{this.props.t('No')}</Button>
                    <Button variant="primary" onClick={() => {this.submitApplyForm(); this.showApplyDialog(false, {})}}>{this.props.t('Yes')}</Button>
                </Modal.Footer>
            </Modal>
        ) : (<p/>);

        let selectRow = undefined
        if (this.onRowSelect !== undefined) {
            const selectedRow = [this.getSelectedRow()]
            selectRow = {
                mode: 'radio',
                clickToSelect: true,
                onSelect: this.handleOnRowSelect,
                hideSelectColumn: true,
                bgColor: 'red',
                selected: selectedRow,
                clickToExpand: true,
            }
        }

        let rowStyle = (row, rowIndex) => {
        }
        /*for (const col of this.input_columns) {
            if (col.field === "EmergencyFund") {
                rowStyle = (row, rowIndex) => {
                    let style = {}
                    if (row.EmergencyFundID == null) {
                        style = {backgroundColor: '#ffcccc'}
                    }
                    return style
                }
                break
            }
        }*/

        //console.log("On render state.isMobile is:", this.state.isMobile)
        let expandRow = undefined
        if (this.state.isMobile === true) {
            //console.log("Adding expand parameters")
            const selectedRow = [this.getSelectedRow()]
            expandRow = {
                renderer: (row) => {
                    return <table>{this.state.columns.map((col, idx) => {
                        return col.text !== undefined && row[col.dataField] !== undefined ? <tr key={idx}>
                            <td>{col.text}</td>
                            <td>{String(row[col.dataField])}</td>
                        </tr> : null
                    })}</table>
                },
                expanded: selectedRow,
                //showExpandColumn: true,
            };

            if (this.onRowSelect === undefined) {
                expandRow["onExpand"] = this.handleOnExpandRow
            }
        }

        console.log("On render columns:", this.state.columns)
        return(
            <div>
                {(this.props.error != null && this.props.error !== undefined) ? (<p>{this.props.t('Error on data request!')} {JSON.stringify(this.props.error)}</p>) : (this.props.isFetching === true ? (<p>{this.props.t('Loading...')}</p>) : (
                    <div>
                        {addButton}
                        {refreshButton}
                        {(this.props.data != null && this.props.data !== undefined) ?
                            (<BootstrapTable
                            data = {this.props.data}
                            columns = {this.state.columns}
                            keyField = 'ID'
                            bootstrap4 = {true}
                            selectRow = {selectRow}
                            rowStyle = { rowStyle }
                            expandRow = { expandRow }
                            striped
                            hover
                            condensed
                            insertRow/>) : (<p>{this.props.t('No data')}</p>)}

                        {addModal}

                        {editModal}

                        {applyModal}
                    </div>
                ))}
            </div>
        )
    }
}

ReduxViewTableComponent.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    columns: PropTypes.array.isRequired,
    onRowSelect: PropTypes.func,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
    onAdd: PropTypes.func,
    onReloadNeeded: PropTypes.func,
    error: PropTypes.string,
    isFetching: PropTypes.bool,
}

export default ReduxViewTableComponent;
