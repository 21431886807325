import React, { useContext } from 'react';
import { ReactReduxContext } from 'react-redux'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/fad-react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import '../node_modules/react-bootstrap-buttons/dist/react-bootstrap-buttons.css';
import "../node_modules/react-month-picker/css/month-picker.css";

import './App.css';

import { useSelector, useDispatch } from 'react-redux'

import MonthlyExpensesComponent from "./components/monthly_expenses.component";
import CreditsComponent from "./components/credits.component";
import AccountsComponent from "./components/accounts.component";
import EmergencyFundsComponent from "./components/emergency_funds.component";
import RefillableFundsComponent from "./components/refillable_funds.component";
import FiniteTargetsComponent from "./components/finite_targets.component";
import StatesComponent from "./components/states.component";
import PlanComponent from "./components/plan.component";
import UserinfoComponent from "./components/userinfo.component";
import PlannedIncomesComponent from "./components/planned_incomes.component";
import LoginComponent from "./components/login.component";
import LogoutComponent from "./components/logout.component";
import RetirementFundsComponent from "./components/retirement_funds.component";
import HowtoComponent from "./components/howto.component";
import MessagesComponent from "./components/messages.component";
import AddComponent from "./components/add.component";
import SignUp from "./components/signup.component";
import DonateComponent from "./components/donate.component";
import HistoryComponent from "./components/history.component";

import ReactGA from "react-ga4";

import {
    BrowserRouter,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory,
    useLocation
} from "react-router-dom";

import Routes from "./Routes"
import Menu from "./Menu"
import { useTranslation, Trans } from 'react-i18next';

// 404
function NoMatch() {
    let location = useLocation();

    return (
        <div>
            <h3>
                No match for <code>{location.pathname}</code>
            </h3>
        </div>
    );
}
// 404
function CreateRoutes (loggedIn) {
    const {t} = useTranslation();
//
    const authorizedRoutes = [
        {
            title: t("Home"),
            path: "/",
            exact: true,
            component: UserinfoComponent,
        },
        {
            showInMenu: false,
            title: t("Home"),
            path: "/signup",
            exact: true,
            component: UserinfoComponent,
        },
        {
            title: t("HowTo"),
            path: "/howto",
            exact: true,
            component: HowtoComponent,
        },
        {
            showInMenu: false,
            title: t("AddEdit"),
            path: "/add/:type",
            component: AddComponent,
        },
        {
            title: t("AddEdit"),
            path: "/add",
            component: AddComponent,
            routes: [
                {
                    path: "/add/periodical_target",
                    title: t("PeriodicalTarget"),
                    component: AccountsComponent,
                },
                {
                    path: "/add/finite_target",
                    title: t("FiniteTarget"),
                    component: FiniteTargetsComponent,
                },
                {
                    path: "/add/emergency_fund",
                    title: t("EmergencyFund"),
                    component: EmergencyFundsComponent,
                },
                {
                    path: "/add/refillable_fund",
                    title: t("RefillableFund"),
                    component: RefillableFundsComponent,
                },
                {
                    path: "/add/retirement_fund",
                    title: t("RetirementFund"),
                    component: RetirementFundsComponent,
                },
                {
                    path: "/add/monthly_expense",
                    title: t("MonthlyExpense"),
                    component: MonthlyExpensesComponent,
                },
                {
                    path: "/add/credit",
                    title: t("Credit"),
                    component: CreditsComponent,
                },
                {
                    path: "/add/planned_income",
                    title: t("PlannedIncome"),
                    component: PlannedIncomesComponent,
                },
            ],
        },
        {
            title: t("States"),
            path: "/states",
            component: StatesComponent,
        },
        {
            title: t("Plan"),
            path: "/plan",
            component: PlanComponent,
        },
        {
            title: t("History"),
            path: "/history",
            component: HistoryComponent,
        },
        {
            title: t("Donate"),
            path: "/donate",
            component: DonateComponent,
        },
        {
            title: t("Logout"),
            path: "/logout",
            component: LogoutComponent,
        },
        {
            showInMenu: false,
            title: "404",
            path: "*",
            component: NoMatch,
        },
    ];
    //
    const unauthorizedRoutes = [
        {
            showInMenu: false,
            title: t("SignUp"),
            path: "/signup",
            component: SignUp
        },
        {
            showInMenu: false,
            title: t("Login"),
            path: "*",
            component: LoginComponent
        },
    ];
    //
    return loggedIn === true ? authorizedRoutes : unauthorizedRoutes;
}

function App() {
    const { store } = useContext(ReactReduxContext)

    console.log(store.getState())
    const unsubscribe = store.subscribe(() => console.log(store.getState()))

    const dispatch = useDispatch()

    const loggedIn = useSelector(state => state.auth.loggedIn)

    //const currentRoutes = loggedIn === true ? authorizedRoutes : unauthorizedRoutes
    const currentRoutes = CreateRoutes(loggedIn)

    ReactGA.initialize('G-B8XGDJCWT9');
    ReactGA.send(window.location.pathname + window.location.search);

    return (
        <>
            <MessagesComponent />
            <BrowserRouter>
                <div>
                    <Menu routes={currentRoutes}></Menu>
                    <Routes routes={currentRoutes}></Routes>
                </div>
            </BrowserRouter>
        </>
    );
    unsubscribe()
}

export default App;
